<template>
  <p
    class="
      text-xl
      font-bold
      bg-primary-default
      border
      text-center
      rounded
      px-2
      py-1
      flex-1
    "
  >
    {{ score[0] }} /
    {{ score[1] }}
  </p>
</template>

<script>
export default {
  props: {
    score: Array,
  },
};
</script>
<template>
  <router-link
    :to="{
      name: 'list-match',
      params: {
        competitionsId: competition.id,
      },
    }"
    class="
      my-4
      bg-primary-600
      px-5
      py-3
      rounded-lg
      flex
      items-center
      justify-between
      gap-x-5
    "
  >
    <div class="rounded-full w-10 h-10 bg-primary-400 p-2">
      <img src="@/assets/icons/fencing.svg" alt="" />
    </div>
    <div class="flex-1">
      <strong>{{ competition.name }} </strong><br /><small>{{
        competition.date.date | formatDate
      }}</small>
      —
      <small>{{ competition.location }}</small>
    </div>
    <div class="text-right flex items-center gap-x-4">
      <img
        v-if="this.competition.state == 'created'"
        class="w-6"
        src="@/assets/icons/created.svg"
        alt=""
      />
      <img
        v-if="this.competition.state == 'over'"
        class="w-6"
        src="@/assets/icons/over.svg"
        alt=""
      />
      <img
        v-if="this.competition.state == 'pending'"
        class="w-6"
        src="@/assets/icons/pending.svg"
        alt=""
      />
      <div @click.prevent="trash()">
        <svg
          width="16"
          height="16"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 11V20.4C19 20.7314 18.7314 21 18.4 21H5.6C5.26863 21 5 20.7314 5 20.4V11"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 17V11"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 17V11"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21 7L16 7M3 7L8 7M8 7V3.6C8 3.26863 8.26863 3 8.6 3L15.4 3C15.7314 3 16 3.26863 16 3.6V7M8 7L16 7"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </router-link>
</template>

<script>
import api from "@/api";

export default {
  props: {
    competition: Object,
    remove: Function,
  },
  data() {
    return {
      icon: "",
    };
  },
  created() {},
  methods: {
    async trash() {
      const res = await api.deleteCompetition(this.competition.id);
      if (!res.error) {
        this.remove(this.competition);
      }
    },
  },
};
</script>
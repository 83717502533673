export const Level = {
    0: 'Finale',
    1: 'Demi-finale',
    2: 'Petite finale',
    3: 'Quart de finale',
    4: 'Huitièmes de finale',
    5: 'Seizième de finale',
    6: 'Poule',
    7: 'Autre'
}
export const Categories = {
    0: 'M10',
    1: 'M14',
    2: 'M17',
    3: 'M20',
    4: 'M23',
    5: 'Senior',
    6: 'Vétéran',
}

export const Club1 = {
    color: "",
    name: "Grenoble Parmentier",
    score: 0,
    logo: "https://www.escrime-parmentier.fr/wp-content/uploads/2020/12/cropped-logo-grenoble-parmentier-escrime.png",
    cards: {
        yellow: 0,
        red: 0,
    },
    team: {
        player1: {
            touches: [
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
            ],
            cards: {
                0: {
                yellow: 0,
                red: 0,
                },
                1: {
                yellow: 0,
                red: 0,
                },
                2: {
                yellow: 0,
                red: 0,
                },
            },
        },
        player2: {
            touches: [
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
            ],
            cards: {
                0: {
                yellow: 0,
                red: 0,
                },
                1: {
                yellow: 0,
                red: 0,
                },
                2: {
                yellow: 0,
                red: 0,
                },
            },
        },
        player3: {
            touches: [
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
            ],
            cards: {
                0: {
                yellow: 0,
                red: 0,
                },
                1: {
                yellow: 0,
                red: 0,
                },
                2: {
                yellow: 0,
                red: 0,
                },
            },
        },
        player7: {
            touches: [
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
            ],
            cards: {
                0: {
                yellow: 0,
                red: 0,
                },
                1: {
                yellow: 0,
                red: 0,
                },
                2: {
                yellow: 0,
                red: 0,
                },
            },
        },
    }
}

export const Club2 = {
    name: "Saint-Gratien",
    score: 0,
    logo: "https://escrime-saintgratien.org/wp-content/uploads/2017/09/LOGO_ST_GRATIEN-ok.svg",
    cards: {
        yellow: 0,
        red: 0,
    },
    team: {
        player4: {
            lastname: "",
            firstname: "",
            touches: [
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
            ],
            cards: {
                0: {
                yellow: 0,
                red: 0,
                },
                1: {
                yellow: 0,
                red: 0,
                },
                2: {
                yellow: 0,
                red: 0,
                },
            },
        },
        player5: {
            lastname: "",
            firstname: "",
            touches: [
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
            ],
            cards: {
                0: {
                yellow: 0,
                red: 0,
                },
                1: {
                yellow: 0,
                red: 0,
                },
                2: {
                yellow: 0,
                red: 0,
                },
            },
        },
        player6: {
            lastname: "",
            firstname: "",
            touches: [
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
            ],
            cards: {
                0: {
                yellow: 0,
                red: 0,
                },
                1: {
                yellow: 0,
                red: 0,
                },
                2: {
                yellow: 0,
                red: 0,
                },
            },
        },
        player8: {
            lastname: "",
            firstname: "",
            touches: [
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0],
            ],
            cards: {
                0: {
                yellow: 0,
                red: 0,
                },
                1: {
                yellow: 0,
                red: 0,
                },
                2: {
                yellow: 0,
                red: 0,
                },
            },
        },
    },
}
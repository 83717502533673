<template>
  <div
    class="
      fixed
      h-full
      w-full
      left-0
      top-0
      bg-primary-full bg-opacity-80
      flex
      items-center
      justify-center
      px-10
      z-50
      gap-y-5
    "
  >
    <div
      class="w-full bg-primary-default py-3 px-5 rounded-lg overflow-scroll"
      style="max-height: 70vh"
    >
      <header class="border-b border-white pb-4 font-bold">
        {{ data.title }}
      </header>
      <div>
        <FormMatch
          v-if="action == 'match'"
          @exit="closeModal"
          :team="data.team"
        />
        <FormFencer v-if="action == 'fencer'" @exit="closeModal" />
        <FormCompetition
          v-if="action == 'competition'"
          @exit="closeModal"
          :competition="data.competition"
          :Categories="data.categories"
        />
        <FormTeam
          v-if="action == 'team'"
          @exit="closeModal"
          :fencers="data.fencers"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormMatch from "@/components/FormMatch.vue";
import FormFencer from "@/components/FormFencer.vue";
import FormCompetition from "@/components/FormCompetition.vue";
import FormTeam from "@/components/FormTeam.vue";

export default {
  components: {
    FormMatch,
    FormFencer,
    FormTeam,
    FormCompetition,
  },
  props: {
    data: Object,
    action: String,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
<template>
  <router-link
    :to="{
      name: 'single-player',
      params: {
        fencerId: player.id,
      },
    }"
    class="
      my-4
      bg-primary-600
      px-5
      py-3
      rounded-lg
      flex
      items-center
      justify-between
      gap-x-5
    "
  >
    <div class="rounded-full w-10 h-10 bg-primary-400 p-2">
      <svg
        v-if="!player.ratio"
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <svg
        v-if="player.ratio >= 5"
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#34FF85"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 10V3L4 14h7v7l9-11h-7z"
        />
      </svg>
      <svg
        v-if="player.ratio < 5"
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#FF6636"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5"
        />
      </svg>
    </div>
    <div class="flex-1">
      <strong>{{ player.firstname }} {{ player.lastname }}</strong
      ><br /><small v-if="player.ratio">{{ player.ratio }}t / match</small>
    </div>
    <div class="text-right flex items-center gap-x-4">
      <div
        :class="getColor(player.year)"
        class="inline-flex items-center px-2 py-1 rounded-full"
        v-if="player.year"
      >
        <small>{{ player.year | category }}</small>
      </div>
      <div @click.prevent="trash()" v-if="canDelete">
        <svg
          width="16"
          height="16"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 11V20.4C19 20.7314 18.7314 21 18.4 21H5.6C5.26863 21 5 20.7314 5 20.4V11"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 17V11"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 17V11"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21 7L16 7M3 7L8 7M8 7V3.6C8 3.26863 8.26863 3 8.6 3L15.4 3C15.7314 3 16 3.26863 16 3.6V7M8 7L16 7"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </router-link>
</template>

<script>
import api from "@/api";

export default {
  props: {
    player: Object,
    remove: Function,
    canDelete: Boolean,
  },
  methods: {
    getColor(year) {
      var now = new Date().getFullYear();
      if (year <= now && year > now - 14) {
        return "bg-green";
      }
      if (year < now - 14 && year >= now - 17) {
        return "bg-secondary";
      }
      if (year < now - 17 && year >= now - 20) {
        return "bg-secondary";
      }
      if (year < now - 20 && year >= now - 23) {
        return "bg-green";
      }
      if (year < now - 24 && year >= now - 39) {
        return "bg-red";
      }
      if (year <= now - 40) {
        return "bg-green";
      }
    },
    async trash() {
      const res = await api.deleteFencer(this.player.id);
      if (!res.error) {
        this.remove(this.player);
      }
    },
  },
};
</script>
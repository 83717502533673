<template>
  <div class="flex justify-between -mb-1 -mx-2">
    <div class="flex justify-center items-center gap-x-1">
      <div
        v-for="(card, yLIndex) in club1.yellow"
        :key="'YL' + yLIndex"
        class="bg-yellow w-3 text-xs text-center text-primary-default"
      >
        P
      </div>
      <div
        v-for="(card, rLIndex) in club1.red"
        :key="'RL' + rLIndex"
        class="bg-red w-3 text-xs text-center"
      >
        P
      </div>
    </div>
    <div class="flex justify-center items-center gap-x-1">
      <div
        v-for="(card, yLIndex) in club2.yellow"
        :key="'YL' + yLIndex"
        class="bg-yellow w-3 text-xs text-center text-primary-default"
      >
        P
      </div>
      <div
        v-for="(card, rLIndex) in club2.red"
        :key="'RL' + rLIndex"
        class="bg-red w-3 text-xs text-center"
      >
        P
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    club1: Object,
    club2: Object,
  },
};
</script>
<template>
  <form @submit.prevent="addTeam">
    <p class="mt-2">Cliquez pour sélectionner ou déselectionner un tireur.</p>
    <ul class="h-56 overflow-scroll my-6 rounded">
      <li
        class="bg-primary-600 rounded p-2 my-2"
        v-for="fencer in fencers"
        :key="fencer.id"
        @click="toggleInTeam(fencer)"
        :class="team.indexOf(fencer) != -1 ? 'bg-secondary' : ''"
      >
        {{ fencer.lastname }} {{ fencer.firstname }}
      </li>
    </ul>

    <p class="text-red" v-if="error.message">{{ error.message }}</p>

    <div class="flex justify-end py-4 gap-x-4 mt-5">
      <button
        @click="$emit('exit')"
        class="bg-primary-400 text-white px-4 py-2 rounded-lg"
      >
        Annuler
      </button>
      <button
        type="submit"
        class="text-white px-4 py-2 rounded-lg"
        :class="
          team.length > 2
            ? 'bg-secondary pointer-events-auto'
            : 'bg-primary-400 pointer-events-none'
        "
      >
        <span v-if="!loading">Valider</span>
        <svg
          v-if="loading"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto"
          width="20px"
          height="20px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#fff"
            stroke-width="10"
            r="35"
            stroke-dasharray="164.93361431346415 56.97787143782138"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
      </button>
    </div>
  </form>
</template>

<script>
import api from "@/api";
export default {
  props: {
    fencers: [],
  },
  data() {
    return {
      form: {},
      error: {},
      loading: false,
      team: [],
    };
  },
  methods: {
    toggleInTeam(fencer) {
      this.error = {};

      if (this.team.indexOf(fencer) == -1) {
        if (this.team.length < 4) {
          this.team.push(fencer);
        } else {
          this.error.message = "Le nombre maximum de coéquipier est atteint.";
        }
      } else {
        var index = this.team.indexOf(fencer);
        this.team.splice(index, 1);
      }
    },
    async addTeam() {
      //submit form
      //then
      this.loading = !this.loading;
      this.error = {};
      const res = await api.createTeam(
        this.team,
        this.$route.params.competitionsId
      );
      if (!res.error) {
        if (res.data.status == "error") {
          this.error.message = res.data.message;
          this.loading = !this.loading;
        } else {
          this.loading = !this.loading;
          this.$emit("exit", true);
        }
      }
    },
  },
};
</script>
<template>
  <form @submit.prevent="addFencer">
    <input
      type="text"
      v-model="form.firstname"
      placeholder="Prénom"
      class="
        bg-primary-900
        p-2
        border border-white
        rounded-lg
        block
        w-full
        focus:border-secondary
        outline-none
        mt-5
      "
    />

    <input
      type="text"
      v-model="form.lastname"
      placeholder="Nom de famille"
      class="
        bg-primary-900
        p-2
        border border-white
        rounded-lg
        block
        w-full
        focus:border-secondary
        outline-none
        my-3
      "
    />
    <select
      class="
        my-4
        bg-primary-900
        p-2
        border border-white
        rounded-lg
        w-full
        block
        outline-none
      "
      v-model="form.year"
      id="year"
    >
      <option value="" disabled selected class="text-white">
        Année de naissance
      </option>

      <option v-for="year in getYears()" :value="year" :key="year">
        {{ year }}
      </option>
    </select>

    <p class="text-red" v-if="error.message">{{ error.message }}</p>

    <div class="flex justify-end py-4 gap-x-4 mt-5">
      <button
        @click="$emit('exit')"
        class="bg-primary-400 text-white px-4 py-2 rounded-lg"
      >
        Annuler
      </button>
      <button
        type="submit"
        class="bg-secondary text-white px-4 py-2 rounded-lg"
      >
        <span v-if="!loading">Valider</span>
        <svg
          v-if="loading"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto"
          width="20px"
          height="20px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#fff"
            stroke-width="10"
            r="35"
            stroke-dasharray="164.93361431346415 56.97787143782138"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
      </button>
    </div>
  </form>
</template>

<script>
import api from "@/api";
export default {
  data() {
    return {
      form: {
        year: "",
      },
      error: {},
      loading: false,
    };
  },
  methods: {
    async addFencer() {
      //submit form
      //then
      this.loading = !this.loading;
      this.error = {};
      const res = await api.createFencer(this.form);
      if (!res.error) {
        if (res.data.status == "error") {
          this.error.message = res.data.message;
          this.loading = !this.loading;
        } else {
          this.loading = !this.loading;
          this.$emit("exit", true);
        }
      }
    },
    getYears() {
      var now = new Date().getFullYear();
      var years = [];
      for (let y = now; y > now - 100; y--) {
        years.push(y);
      }
      return years;
    },
  },
};
</script>
<template>
  <form @submit.prevent="addMatch">
    <select
      class="
        my-4
        bg-primary-900
        p-2
        border border-white
        rounded-lg
        w-full
        block
        outline-none
      "
      name="level"
      v-model="form.level"
      id="level"
    >
      <option value="" disabled selected class="text-white">Tableau</option>

      <option v-for="(level, index) in levels" :value="index" :key="index">
        {{ level }}
      </option>
    </select>

    <input
      type="text"
      v-model="form.opponent"
      placeholder="Adversaire"
      class="
        bg-primary-900
        p-2
        border border-white
        rounded-lg
        block
        w-full
        focus:border-secondary
        outline-none
      "
    />

    <div class="flex justify-end py-4 gap-x-4 mt-5">
      <button
        @click="$emit('exit')"
        class="bg-primary-400 text-white px-4 py-2 rounded-lg"
      >
        Annuler
      </button>
      <button
        type="submit"
        class="bg-secondary text-white px-4 py-2 rounded-lg"
      >
        Valider
      </button>
    </div>
  </form>
</template>

<script>
import { Level } from "@/constants";
import api from "@/api";

export default {
  props: {
    team: Array,
  },
  data() {
    return {
      form: {
        level: "",
      },
      levels: {},
      error: {},
      loading: false,
    };
  },
  created() {
    this.levels = Level;
  },
  methods: {
    async addMatch() {
      localStorage.clear;
      //submit form
      //then
      this.loading = !this.loading;
      this.error = {};
      const res = await api.createMatch(
        this.form,
        this.$route.params.competitionsId
      );
      if (!res.error) {
        if (res.data.status == "error") {
          this.error.message = res.data.message;
          this.loading = !this.loading;
        } else {
          this.loading = !this.loading;
          this.$router.push({
            name: "single-match",
            params: {
              competitionsId: this.$route.params.competitionsId,
              matchId: res.data.id,
            },
          });
        }
      }
    },
  },
};
</script>
<template>
  <form
    class="
      fixed
      h-full
      w-full
      left-0
      top-0
      bg-primary-full bg-opacity-80
      flex
      items-center
      justify-center
      px-10
      z-50
      gap-y-5
    "
    @submit.prevent="submit"
  >
    <div
      class="w-full bg-primary-default py-3 px-5 rounded-lg overflow-scroll"
      style="max-height: 70vh"
    >
      <header class="border-b border-white pb-4 text-lg font-bold">
        Composez les équipes
      </header>
      <div class="my-5 flex justify-between items-center">
        <h2 class="font-bold">Mon équipe</h2>
        <select
          class="bg-primary-default border border-white rounded-lg px-2 py-1"
          name="position"
          id="position"
          @change="onChange($event)"
        >
          <option value="0">Haut</option>
          <option value="1">Bas</option>
        </select>
      </div>
      <div class="mt-5">
        <div v-for="(item, index) in 3" :key="index" class="my-4">
          <div v-if="clubPosition == 0 ? (range = item) : (range = item + 3)">
            <select
              class="
                bg-primary-900
                p-2
                border border-white
                rounded-lg
                w-full
                block
                outline-none
              "
              v-model="myteam['player' + item]"
              :name="'team' + (clubPosition + 1)"
              :id="'player' + range"
            >
              <option value="" selected="selected">Place {{ range }}</option>

              <option
                v-for="player in club1.team"
                :value="player"
                :key="player.id"
              >
                {{ player.firstname }}
                {{ player.lastname }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="this.club1.team.player7">
          <select
            class="
              bg-primary-900
              p-2
              border border-white
              rounded-lg
              w-full
              block
              outline-none
            "
            v-if="clubPosition == 0 ? (place = 7) : (place = 8)"
            v-model="myteam['playerR']"
            name="team"
            :id="'playerR'"
          >
            <option value="" selected="selected">Remplaçant</option>
            <option
              v-for="(player, index) in club1.team"
              :value="player"
              :key="index"
            >
              {{ player.firstname }}
              {{ player.lastname }}
            </option>
          </select>
        </div>
      </div>

      <div class="mt-10">
        <h2 class="font-bold">Adversaire</h2>
        <div v-for="n in 3" :key="n">
          <input
            class="
              block
              w-full
              rounded-lg
              border border-white
              bg-primary-default
              my-2
              p-2
              outline-none
              focus:border-secondary
            "
            v-if="!clubPosition ? (rangeB = n + 3) : (rangeB = n)"
            type="text"
            :placeholder="'Place ' + rangeB"
            v-model="opponent['player' + n]"
            :id="rangeB"
          />
        </div>
        <div>
          <input
            class="
              block
              w-full
              rounded-lg
              border border-white
              bg-primary-default
              my-2
              p-2
              outline-none
              focus:border-secondary
            "
            v-if="!clubPosition ? (placeB = 8) : (placeB = 7)"
            v-model="opponent['playerR']"
            type="text"
            :placeholder="'Place R'"
            :id="placeB"
          />
        </div>
      </div>
      <div class="my-5">
        <h2 class="font-bold">Couleur de ma lampe</h2>
        <div class="flex gap-x-2 mt-2">
          <div
            @click="color('green')"
            class="bg-green w-5 h-5 rounded-full border-2"
            :class="
              finalTeam.color == 'green'
                ? 'border-white'
                : 'border-primary-default'
            "
          ></div>
          <div
            @click="color('red')"
            class="bg-red w-5 h-5 rounded-full border-2"
            :class="
              finalTeam.color == 'red'
                ? 'border-white'
                : 'border-primary-default'
            "
          ></div>
        </div>
      </div>
      <div class="flex items-center justify-center mb-20 mt-10 gap-x-5">
        <button class="bg-primary-400 text-white px-4 py-2 rounded-lg">
          Annuler
        </button>
        <button
          type="submit"
          class="bg-secondary text-white px-4 py-2 rounded-lg"
        >
          <span v-if="!loading">Valider</span>
          <svg
            v-if="loading"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="margin: auto"
            width="20px"
            height="20px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              stroke="#fff"
              stroke-width="10"
              r="35"
              stroke-dasharray="164.93361431346415 56.97787143782138"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import api from "@/api";

export default {
  props: {
    club1: Object,
    club2: Object,
  },
  data() {
    return {
      form: {},
      myteam: {},
      opponent: {},
      clubPosition: 0,
      finalTeam: {},
      opponentTeam: {},
      loading: false,
      error: {},
    };
  },
  created() {
    this.finalTeam = this.club1;
    this.opponentTeam = this.club2;
  },
  methods: {
    onChange(event) {
      this.clubPosition = event.target.value;
    },
    async submit() {
      if (!this.clubPosition) {
        //mon équipe est en haut
        this.finalTeam.team = {
          player1: this.myteam.player1,
          player2: this.myteam.player2,
          player3: this.myteam.player3,
          player7: this.myteam.playerR,
        };
        this.finalTeam.place = 0;

        this.opponentTeam.team.player4.lastname = this.opponent.player1;
        this.opponentTeam.team.player5.lastname = this.opponent.player2;
        this.opponentTeam.team.player6.lastname = this.opponent.player3;
        this.opponentTeam.team.player8.lastname = this.opponent.playerR;
      } else {
        this.finalTeam.team = {
          player4: this.myteam.player1,
          player5: this.myteam.player2,
          player6: this.myteam.player3,
          player8: this.myteam.playerR,
        };
        this.opponentTeam.team.player1 = this.opponentTeam.team.player4;
        this.opponentTeam.team.player2 = this.opponentTeam.team.player5;
        this.opponentTeam.team.player3 = this.opponentTeam.team.player6;
        this.opponentTeam.team.player7 = this.opponentTeam.team.player8;

        const array = ["player4", "player5", "player6", "player8"];
        this.opponentTeam.team = Object.fromEntries(
          Object.entries(this.opponentTeam.team).filter(
            ([key]) => array.indexOf(key) == -1
          )
        );

        this.opponentTeam.team.player1.lastname = this.opponent.player1;
        this.opponentTeam.team.player2.lastname = this.opponent.player2;
        this.opponentTeam.team.player3.lastname = this.opponent.player3;
        this.opponentTeam.team.player7.lastname = this.opponent.playerR;
        this.finalTeam.place = 1;
      }
      const res = await api.updateMatch(
        this.finalTeam,
        this.opponentTeam,
        this.$route.params.matchId,
        this.$route.params.competitionsId
      );
      if (!res.error) {
        if (res.data.status == "error") {
          this.error.message = res.data.message;
          this.loading = !this.loading;
        } else {
          this.loading = !this.loading;
          this.$router.go();
        }
      }
    },
    color(color) {
      this.finalTeam.color = color;
      if (color == "green") {
        this.opponentTeam.color = "red";
      } else {
        this.opponentTeam.color = "green";
      }
    },
  },
};
</script>
<template>
  <div class="bg-primary-default">
    <div class="bg-primary-900 rounded-lg p-5 relative">
      <State :state="competition.state" />
      <h1 class="text-center text-xl font-bold">
        {{ competition.name }}
      </h1>
      <p class="text-center mt-1">
        {{ competition.weapon }} —
        <span v-if="competition.date">{{
          competition.date.date | formatDate
        }}</span>
        — {{ competition.location }}<br />
        {{ Categories[competition.category] }} — {{ competition.gender }}
      </p>
    </div>

    <div class="mt-10">
      <div class="flex justify-between items-center mb-5">
        <h2 class="text-lg font-bold">Mes matches</h2>
        <div
          class="flex justify-end gap-x-2"
          v-if="
            (competition && competition.state == 'pending') ||
            (competition &&
              competition.state == 'created' &&
              competition.team &&
              competition.team.length > 0)
          "
        >
          <div
            @click="createMatch()"
            class="
              bg-secondary
              flex
              items-center
              justify-around
              rounded-full
              w-10
              h-10
              p-2
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </div>
          <!-- <div
            @click="more"
            class="
              bg-primary-600
              flex
              items-center
              justify-around
              rounded-full
              w-10
              h-10
              p-2
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
              />
            </svg>
          </div> -->
        </div>
      </div>
      <div v-if="competition && competition.matches">
        <SingleMatch
          v-for="(match, index) in competition.matches"
          :key="index"
          :match="match"
          :remove="removeMatch"
        />
      </div>

      <h2 class="mt-10 mb-5 text-lg font-bold">Mon équipe</h2>
      <div
        v-if="competition && competition.team && competition.team.length > 0"
      >
        <SinglePlayer
          v-for="(player, index) in competition.team"
          :key="index"
          :player="player"
          :canDelete="false"
        />
      </div>
      <div v-else>
        <p class="mb-4 bg-primary-600 p-3 rounded">
          La constitution de votre équipe pour cette compétition est obligatoire
          avant de saisir un match
        </p>
        <button @click="createTeam()" class="bg-red text-white rounded p-2">
          Créer mon équipe
        </button>
      </div>
    </div>
    <Modal @close="close" action="match" v-if="modal" :data="data" />
    <Modal @close="close" action="team" v-if="modalTeam" :data="data" />
  </div>
</template>

<script>
import State from "@/components/State.vue";
import SingleMatch from "@/components/SingleMatch.vue";
import Modal from "@/components/Modal.vue";
import SinglePlayer from "@/components/SinglePlayer.vue";
import api from "@/api";
import { Categories } from "@/constants";

export default {
  components: {
    State,
    SingleMatch,
    SinglePlayer,
    Modal,
  },
  data() {
    return {
      competition: {},
      data: {},
      modal: false,
      modalTeam: false,
      Categories: Categories,
    };
  },
  created() {
    this.getCompetition();
  },
  methods: {
    async getCompetition() {
      const res = await api.getCompetition(this.$route.params.competitionsId);
      if (!res.error) {
        this.competition = res.data;
        console.log(this.competition);
      }
    },
    async createTeam() {
      const res = await api.getFencers();
      if (!res.error) {
        this.data = {
          title: "Composer mon équipe",
          fencers: res.data,
        };
        this.modalTeam = !this.modalTeam;
      }
    },
    createMatch() {
      this.data = {
        title: "Créer un match",
        team: this.competition.team,
      };
      this.modal = !this.modal;
    },
    removeMatch(match) {
      const index = this.competition.matches.indexOf(match);
      if (index > -1) {
        this.competition.matches.splice(index, 1);
      }
    },
    close() {
      this.modal = false;
      this.modalTeam = false;
      this.getCompetition();
    },
  },
};
</script>
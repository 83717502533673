<template>
  <div class="bg-primary-default">
    <InitMatch v-if="init" :club1="club1" :club2="club2" />
    <div v-else-if="go && !init && club1">
      <div v-if="go && !init && club1" class="flex justify-between mt-4 pb-40">
        <div>
          <div
            v-for="(match, index) in order1"
            :key="index"
            :class="[
              currentMatch == index + 1 ? 'border-secondary' : 'border-white',
              currentMatch > index + 1 ? 'opacity-50' : '',
            ]"
            @click="
              toggleDeploy(
                order1[index],
                getCurrentRelayForPlayer(index),
                index
              )
            "
            class="
              bg-primary-900
              rounded-xl
              border
              w-36
              text-center
              my-4
              py-2
              pr-3
              pl-2
            "
          >
            <div class="flex items-center justify-end gap-x-3">
              <div class="text-right truncate">
                <p>
                  {{ club1.team["player" + order1[index]].lastname }}
                </p>
                <div
                  v-if="
                    deploy['player' + order1[index]][
                      getCurrentRelayForPlayer(index)
                    ] || currentMatch == index + 1
                  "
                  class="flex justify-end flex-wrap gap-x-1 my-1"
                >
                  <div
                    class="w-1 h-1 rounded-full my-1"
                    v-for="(touche, index) in club1.team[
                      'player' + order1[index]
                    ].touches[getCurrentRelayForPlayer(index)]"
                    :key="index"
                    :class="
                      touche == 1
                        ? 'bg-' + club1.color + ' bg-opacity-100'
                        : 'bg-white bg-opacity-25'
                    "
                  ></div>
                </div>
              </div>
              <div class="bg-white w-px h-6"></div>
              <div>
                {{
                  getPlayerScore(
                    1,
                    order1[index],
                    getCurrentRelayForPlayer(index)
                  )
                }}
                <!-- cartons -->
                <div
                  v-if="
                    deploy['player' + order1[index]][
                      getCurrentRelayForPlayer(index)
                    ] || currentMatch == index + 1
                  "
                  class="flex items-center gap-x-1 mt-1"
                >
                  <div
                    v-for="card in club1.team['player' + order1[index]].cards[
                      getCurrentRelayForPlayer(index)
                    ].yellow"
                    :key="card"
                    class="bg-yellow h-2 w-1"
                  ></div>
                  <div
                    v-for="(card, index) in club1.team['player' + order1[index]]
                      .cards[getCurrentRelayForPlayer(index)].red"
                    :key="index"
                    class="bg-red h-2 w-1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- MILIEU -->
        <div class="bg-primary-900 h-92 w-2">
          <div class="fixed left-1/2 -translate-x-1/2 transform top-16">
            <Cards :club1="club1.cards" :club2="club2.cards" />
            <Score :score="[club1.score, club2.score]" />
            <Undo v-if="lastActions.length > 0" :cancelAction="cancelAction" />
          </div>
        </div>

        <!-- DROITE -->
        <div>
          <div
            v-for="(match, index) in order1"
            :key="index"
            :class="[
              currentMatch == index + 1 ? 'border-secondary' : 'border-white',
              currentMatch > index + 1 ? 'opacity-50' : '',
            ]"
            @click="
              toggleDeploy(
                order2[index],
                getCurrentRelayForPlayer(index),
                index
              )
            "
            class="
              bg-primary-900
              rounded-xl
              border
              w-36
              text-center
              my-4
              py-2
              pr-3
              pl-2
            "
          >
            <div class="flex items-center justify-start gap-x-3">
              <div>
                {{
                  getPlayerScore(
                    2,
                    order2[index],
                    getCurrentRelayForPlayer(index)
                  )
                }}
                <!-- cartons -->
                <div
                  v-if="
                    deploy['player' + order2[index]][
                      getCurrentRelayForPlayer(index)
                    ] || currentMatch == index + 1
                  "
                  class="flex items-center gap-x-1 mt-1"
                >
                  <div
                    v-for="card in club2.team['player' + order2[index]].cards[
                      getCurrentRelayForPlayer(index)
                    ].yellow"
                    :key="card"
                    class="bg-yellow h-2 w-1"
                  ></div>
                  <div
                    v-for="(card, index) in club2.team['player' + order2[index]]
                      .cards[getCurrentRelayForPlayer(index)].red"
                    :key="index"
                    class="bg-red h-2 w-1"
                  ></div>
                </div>
              </div>
              <div class="bg-white w-px h-6"></div>

              <div class="text-left truncate">
                <p class="">
                  {{ club2.team["player" + order2[index]].lastname }}
                </p>
                <div
                  v-if="
                    deploy['player' + order2[index]][
                      getCurrentRelayForPlayer(index)
                    ] || currentMatch == index + 1
                  "
                  class="flex justify-start flex-wrap gap-x-1 my-1"
                >
                  <div
                    class="w-1 h-1 rounded-full my-1"
                    v-for="(touche, index) in club2.team[
                      'player' + order2[index]
                    ].touches[getCurrentRelayForPlayer(index)]"
                    :key="index"
                    :class="
                      touche == 1
                        ? 'bg-' + club2.color + ' red bg-opacity-100'
                        : 'bg-white bg-opacity-25'
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Fixed actions -->

      <!-- MODAL ACTION -->
      <div
        class="
          fixed
          h-full
          w-full
          left-0
          top-0
          bg-primary-full bg-opacity-90
          flex flex-col
          justify-center
          px-20
          z-50
          gap-y-5
        "
        v-if="action.player"
      >
        <p class="text-lg text-center mb-2">
          <small>Action appliquée à :</small> <br />{{
            action.player.firstname
          }}
          {{ action.player.lastname }}
        </p>
        <div
          class="
            border border-yellow
            px-3
            py-2
            bg-primary-full
            rounded-xl
            text-center
          "
          @click="colorCard('yellow')"
        >
          Yellow card
        </div>
        <div
          class="
            border border-red
            px-3
            py-2
            bg-primary-full
            rounded-xl
            mb-5
            text-center
          "
          @click="colorCard('red')"
        >
          Red card
        </div>

        <hr class="bg-white" />

        <p class="text-center">
          Cartons Passivité<br /><small>Appliqués à l'équipe</small>
        </p>
        <div
          class="
            border border-yellow
            px-3
            py-2
            bg-primary-full
            rounded-xl
            text-center
          "
          @click="colorCardP('yellow')"
        >
          Yellow P card
        </div>
        <div
          class="
            border border-red
            px-3
            py-2
            bg-primary-full
            rounded-xl
            text-center
          "
          @click="colorCardP('red')"
        >
          Red P card
        </div>

        <hr class="bg-white" />

        <div
          class="
            border border-white
            px-3
            py-2
            bg-primary-full
            rounded-xl
            text-center
            mt-5
          "
          @click="closeAction()"
        >
          Annuler
        </div>
      </div>

      <div
        class="
          fixed
          bottom-16
          left-5
          right-5
          py-3
          justify-center
          rounded-xl
          flex
        "
        v-if="finish"
      >
        <p class="flex justify-center gap-x-5">
          <router-link
            :to="{
              name: 'list-match',
              params: {
                competitionsId: $route.params.competitionsId,
              },
            }"
            class="
              bg-red
              text-white
              rounded-full
              w-10
              h-10
              flex
              items-center
              justify-center
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
              />
            </svg>
          </router-link>
        </p>
      </div>
      <div
        class="
          fixed
          bottom-16
          left-5
          right-5
          h-12
          rounded-xl
          bg-primary-full
          flex
        "
        v-if="!finish"
      >
        <div class="absolute w-1/3 flex items-center flex-col -top-10 left-2">
          <div
            class="
              bg-primary-full
              rounded-full
              h-20
              w-20
              items-center
              flex
              justify-center
            "
            @click="touchClub(1, 'simple')"
          >
            <div
              :class="'border-' + club1.color"
              class="
                border
                rounded-full
                h-16
                w-16
                flex
                items-center
                justify-center
              "
            >
              <div
                :class="'bg-' + club1.color"
                class="h-10 w-10 rounded-full"
              ></div>
            </div>
          </div>
          <div
            :class="'bg-' + club1.color"
            class="
              text-white
              px-2
              py-1
              flex
              w-6
              items-center
              justify-center
              rounded-full
              leading-none
            "
            @click="addAction(1)"
          >
            +
          </div>
        </div>
        <div
          class="
            1/3
            absolute
            left-1/2
            transform
            -translate-x-1/2
            font-bold
            -top-10
            text-center text-lg text-secondary
          "
        >
          <div
            class="
              bg-primary-full
              p-2
              rounded-full
              flex
              items-center
              justify-center
            "
            @click="double()"
          >
            <div class="border rounded-full border-secondary p-1">
              <div class="flex h-6 w-6 rounded-full overflow-hidden">
                <div class="bg-red h-full w-1/2"></div>
                <div class="bg-green h-full w-1/2"></div>
              </div>
            </div>
          </div>

          <p class="mt-2" v-if="currentMatch < 10">R{{ currentMatch }}</p>

          <div
            class="
              bg-primary-full
              p-2
              rounded-full
              flex
              items-center
              justify-center
              text-white
              mx-auto
              font-normal
              h-6
              text-xs
            "
            @click="nextRelay()"
          >
            {{ getWording() }}
          </div>
        </div>
        <div class="absolute w-1/3 flex items-center flex-col -top-10 right-2">
          <div
            class="
              bg-primary-full
              rounded-full
              h-20
              w-20
              items-center
              flex
              justify-center
            "
            @click="touchClub(2, 'simple')"
          >
            <div
              class="
                border
                rounded-full
                h-16
                w-16
                flex
                items-center
                justify-center
              "
              :class="'border-' + club2.color"
            >
              <div
                :class="'bg-' + club2.color"
                class="h-10 w-10 rounded-full"
              ></div>
            </div>
          </div>
          <div
            :class="'bg-' + club2.color"
            class="
              text-white
              px-2
              py-1
              flex
              w-6
              items-center
              justify-center
              rounded-full
              leading-none
            "
            @click="addAction(2)"
          >
            +
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InitMatch from "@/components/InitMatch.vue";
import Score from "@/components/Match/Score.vue";
import Cards from "@/components/Match/Cards.vue";
import Undo from "@/components/Match/Undo.vue";
import { Club1, Club2 } from "@/constants";
import api from "@/api";

export default {
  components: {
    InitMatch,
    Score,
    Cards,
    Undo,
  },
  props: {
    level: { type: String, default: "Huitièmes de finale" },
  },
  data() {
    return {
      club1: {},
      club2: {},
      send1: {},
      send2: {},
      order1: [3, 1, 2, 1, 3, 2, 1, 2, 3],
      order2: [6, 5, 4, 6, 4, 5, 4, 6, 5],
      currentMatch: 1,
      currentTouch: 0,
      deploy: {
        player1: { 0: false, 1: false, 2: false },
        player2: { 0: false, 1: false, 2: false },
        player3: { 0: false, 1: false, 2: false },
        player4: { 0: false, 1: false, 2: false },
        player5: { 0: false, 1: false, 2: false },
        player6: { 0: false, 1: false, 2: false },
      },
      finish: false,
      winner: 0,
      action: {},
      init: false,
      go: false,
      lastActions: [],
    };
  },
  async mounted() {
    this.club1 = localStorage.club1;
    this.club2 = localStorage.club2;
    if (!localStorage.club1 || !localStorage.club2) {
      var competition = this.$route.params.competitionsId;
      var match = this.$route.params.matchId;
      if (match) {
        const res = await api.getMatch(
          this.$route.params.matchId,
          this.$route.params.competitionsId
        );
        if (!res.error) {
          if (res.data.status == "success") {
            console.log(res.data);
            this.club1 = res.data.club1;
            this.club2 = res.data.club2;

            var that = this;
            this.club1.team.forEach(function (element) {
              that.club1.team["player" + element.position] = element;
            });
            this.club2.team.forEach(function (element) {
              that.club2.team["player" + element.position] = element;
            });

            this.club1.team = Object.fromEntries(
              Object.entries(this.club1.team).filter(([key]) => key.length > 1)
            );
            this.club2.team = Object.fromEntries(
              Object.entries(this.club2.team).filter(([key]) => key.length > 1)
            );

            this.storeData();
            this.init = false;
            this.getClubs();
          } else {
            if (competition) {
              const team = await api.getCompetition(
                this.$route.params.competitionsId
              );
              if (!team.error) {
                this.club1 = Club1; //CHANGE IT TO CREATE FROM DB
                for (let i = 1; i < 5; i++) {
                  if (team.data.team[i - 1] && i != 4) {
                    this.club1.team["player" + i].id = team.data.team[i - 1].id;
                    this.club1.team["player" + i].firstname =
                      team.data.team[i - 1].firstname;
                    this.club1.team["player" + i].lastname =
                      team.data.team[i - 1].lastname;
                  } else {
                    console.log(i);
                    if (team.data.team[i - 1]) {
                      this.club1.team["player7"].id = team.data.team[i - 1].id;
                      this.club1.team["player7"].firstname =
                        team.data.team[i - 1].firstname;
                      this.club1.team["player7"].lastname =
                        team.data.team[i - 1].lastname;
                    }
                  }
                }
              }
              this.club2 = Club2;
              this.init = true;
            }
          }
        }
      }
    } else {
      this.getClubs();
    }
  },
  methods: {
    getClubs() {
      //find match information with db values
      //in localStorage, information about the user club is in club1
      this.club1 = JSON.parse(localStorage.club1);
      this.club2 = JSON.parse(localStorage.club2);

      if (this.club1.position) {
        //user club is en bas
        this.club1 = JSON.parse(localStorage.club2);
        this.club2 = JSON.parse(localStorage.club1);
        if (this.club2.color == "green") {
          this.club1.color = "red";
        } else {
          this.club1.color = "green";
        }
      } else {
        //user club is en haut
        this.club1 = JSON.parse(localStorage.club1);
        this.club2 = JSON.parse(localStorage.club2);
        if (this.club1.color == "green") {
          this.club2.color = "red";
        } else {
          this.club2.color = "green";
        }
      }

      if (localStorage.relay != 0 && !isNaN(localStorage.relay)) {
        this.currentMatch = parseInt(localStorage.relay);
      } else {
        this.currentMatch = 1;
        localStorage.relay = 1;
      }
      this.init = false;
      this.go = !this.go;
    },
    storeData() {
      localStorage.club1 = JSON.stringify(this.club1);
      localStorage.club2 = JSON.stringify(this.club2);
      if (this.finish) {
        this.save();
      }
    },
    toggleDeploy(player, relay, match) {
      //open
      var playerLeft = this.order1[match];
      const currentLeft = this.deploy["player" + playerLeft];
      currentLeft[relay] = !currentLeft[relay];

      var playerRight = this.order2[match];
      const currentRight = this.deploy["player" + playerRight];
      currentRight[relay] = !currentRight[relay];

      //close other informations
      for (const [key, value] of Object.entries(this.deploy)) {
        for (const val of Object.entries(value)) {
          if (val[0] != relay) {
            this.deploy[key][val[0]] = false;
          }
        }
      }
    },
    getPlayerScore(club, player, relay) {
      var arr = this["club" + club].team["player" + player].touches[relay];
      var scorePlayer = 0;
      arr.forEach((touch) => {
        if (touch == 1) {
          scorePlayer += 1;
        }
      });
      return scorePlayer;
    },
    nextRelay() {
      this.currentMatch += 1;
      this.currentTouch = 0;

      if (this.currentMatch > 9) {
        this.finish = true;
      }
      this.storeData();
      localStorage.relay = parseInt(this.currentMatch);
    },
    getWording() {
      if (this.finish) {
        return "end";
      } else {
        return "next";
      }
    },
    saveAction(club, action, player) {
      if (!player) {
        player = [
          this["order1"][this.currentMatch - 1],
          this["order2"][this.currentMatch - 1],
        ];
      }
      var currentAction = {
        club: club,
        currentMatch: this.currentMatch,
        currentTouch: this.currentTouch,
        action: action,
        playerRelay: this.getRelay(),
        player: player,
      };
      this.lastActions.push(currentAction);
      console.log(this.lastActions);
    },
    cancelAction() {
      var last = this.lastActions.pop();

      if (last.action == "simple") {
        //ajustement du score
        var club = "club" + last.club;
        this[club].score--;

        //annule la touche du player
        this[club].team["player" + last.player[0]].touches[last.playerRelay][
          last.currentTouch
        ] = 0;
      } else {
        //annule la touche des 2 players
        this.club1.team["player" + last.player[0]].touches[last.playerRelay][
          last.currentTouch
        ] = 0;
        this.club2.team["player" + last.player[1]].touches[last.playerRelay][
          last.currentTouch
        ] = 0;
        this.club1.score--;
        this.club2.score--;
      }

      if (this.currentTouch == 0 && this.currentMatch != 1) {
        //revient au match précédent
        this.currentMatch--;
      }
      if (this.currentMatch == last.currentMatch) {
        this.currentTouch = last.currentTouch;
      }
      if (this.currentTouch > 4) {
        //on enlève de l'array toutes les touches > à la touche en cours
        this.checkTouch(this.currentTouch);
      }

      this.storeData();
    },
    checkTouch(currentTouch) {
      var relay = this.getRelay();
      var player1 = this["order1"][this.currentMatch - 1];
      var player2 = this["order2"][this.currentMatch - 1];
      var array = this.club2.team["player" + player2].touches[relay];
      var array2 = this.club1.team["player" + player1].touches[relay];
      array.splice(currentTouch, array.length);
      array2.splice(currentTouch, array2.length);
    },
    touchClub(club, action) {
      var player = this["order" + club][this.currentMatch - 1];

      if (action == "simple") {
        this.saveAction(club, action, [player]);
      }

      this["club" + club].score = parseInt(this["club" + club].score) + 1;

      //determine player's relay
      var relay = this.getRelay();

      //incremente les touches du player
      this["club" + club].team["player" + player].touches[relay][
        this.currentTouch
      ] = 1;

      if (action != "double") {
        this["club" + this.getOtherClub(club)].team[
          "player" + this.getOpponent(club, this.currentMatch)
        ].touches[relay][this.currentTouch] = 0;
        this.currentTouch += 1;
        //Next relay if max touch of currentMatch
        if (this["club" + club].score == this.currentMatch * 5) {
          this.currentMatch += 1;
          this.currentTouch = 0;
        }
        //end of the match
        if (this["club" + club].score == 45) {
          this.finish = true;
          this.winner = this["club" + club].name;
        }
        this.storeData();
      }
      localStorage.relay = parseInt(this.currentMatch);
    },
    getOtherClub(club) {
      if (club == 1) {
        return 2;
      } else {
        return 1;
      }
    },
    getOpponent(club, match) {
      if (club == 1) {
        return this.order2[match - 1];
      } else {
        return this.order1[match - 1];
      }
    },
    double() {
      for (var club = 1; club <= 2; club++) {
        this.touchClub(club, "double");
      }
      this.saveAction("0", "double");
      this.currentTouch += 1;
      if (
        this["club1"].score == this.currentMatch * 5 ||
        this["club2"].score == this.currentMatch * 5
      ) {
        this.currentMatch += 1;
        this.currentTouch = 0;
      }
      this.storeData();
    },
    getCurrentRelayForPlayer(index) {
      if (index < 3) {
        return 0;
      } else if (index >= 3 && index < 6) {
        return 1;
      } else {
        return 2;
      }
    },
    getRelay() {
      var relay = this.currentMatch / 3;
      if (relay <= 1) {
        relay = 0;
      } else if (relay <= 2) {
        relay = 1;
      } else {
        relay = 2;
      }
      return relay;
    },
    addAction(club) {
      var numPlayer = this["order" + club][this.currentMatch - 1];
      var player = this["club" + club].team["player" + numPlayer];
      this.action = {
        club: club,
        player: player,
        numPlayer: numPlayer,
      };
    },
    colorCard(color) {
      this["club" + this.action.club].team[
        "player" + this.action.numPlayer
      ].cards[this.getRelay()][color] += 1;

      if (color == "red") {
        this.touchClub(this.getOtherClub(this.action.club), "simple");
      }

      this.action = {};
      this.storeData();
    },
    colorCardP(color) {
      this["club" + this.action.club].cards[color] += 1;
      if (color == "red") {
        this.touchClub(this.getOtherClub(this.action.club), "simple");
      }
      this.action = {};
      this.storeData();
    },
    closeAction() {
      this.action = {};
    },
    async save() {
      var us = {};
      if (this.club1.opponent == "username") {
        us = this.club2;
      } else {
        us = this.club1;
      }
      if (us.position) {
        //user club is en bas
        var intermediate1 = this.club1;
        var intermediate2 = this.club2;
        this.send1 = intermediate2;
        this.send2 = intermediate1;
      } else {
        this.send1 = this.club1;
        this.send2 = this.club2;
      }
      var win = false;
      if (this.send1.score > this.send2.score) {
        win = true;
      }
      console.log(win);
      const data = await api.saveMatch(
        this.$route.params.competitionsId,
        this.$route.params.matchId,
        this.send1,
        this.send2,
        win
      );
      if (!data.error) {
        localStorage.clear;
      }
    },
  },
};
</script>
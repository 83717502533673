<template>
  <router-link
    :to="{
      name: 'single-match',
      params: {
        competitionsId: $route.params.competitionsId,
        matchId: match.id,
      },
    }"
    class="
      my-4
      bg-primary-600
      px-5
      py-3
      rounded-lg
      flex
      items-center
      justify-between
      gap-x-5
    "
  >
    <div class="rounded-full w-10 h-10 bg-primary-400 p-2">
      <svg
        v-if="match.result"
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#34FF85"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
        />
      </svg>
      <svg
        v-if="match.result === false"
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#FF6636"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5"
        />
      </svg>
    </div>
    <div class="flex-1">
      <strong>{{ Level[match.level] }}</strong
      ><br />
      {{ match.opponent }}
    </div>
    <div class="text-right flex items-center gap-x-4">
      {{ match.score }} / {{ match.scoreOp }}
      <div @click.prevent="trash(match)">
        <svg
          width="16"
          height="16"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 11V20.4C19 20.7314 18.7314 21 18.4 21H5.6C5.26863 21 5 20.7314 5 20.4V11"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 17V11"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 17V11"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21 7L16 7M3 7L8 7M8 7V3.6C8 3.26863 8.26863 3 8.6 3L15.4 3C15.7314 3 16 3.26863 16 3.6V7M8 7L16 7"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </router-link>
</template>

<script>
import { Level } from "@/constants";
import api from "@/api";

export default {
  props: {
    match: Object,
    remove: Function,
  },
  data() {
    return {
      Level: Level,
    };
  },
  methods: {
    async trash(match) {
      const res = await api.deleteMatch(
        this.$route.params.competitionsId,
        match.id
      );
      if (!res.error) {
        this.remove(match);
      }
    },
  },
};
</script>
<template>
  <div class="bg-primary-default">
    <div
      class="
        bg-primary-900
        rounded-lg
        p-5
        relative
        flex
        justify-between
        items-center
        mb-5
      "
    >
      <h2 class="text-lg font-bold">Mes compétitions</h2>
      <div class="flex justify-end gap-x-2">
        <div
          @click="createCompetition()"
          class="
            bg-secondary
            flex
            items-center
            justify-around
            rounded-full
            w-10
            h-10
            p-2
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 4v16m8-8H4"
            />
          </svg>
        </div>
      </div>
    </div>

    <!-- <form
      action=""
      class="
        flex
        rounded
        items-center
        gap-x-2
        justify-between
        p-2
        bg-primary-600
      "
    >
      <input
        type="search"
        class="bg-primary-400 px-2 py-1 rounded h-8 w-1/2"
        placeholder="Rechercher une compétition"
      />
      <select name="" id="" class="bg-primary-400 w-1/4 px-2 py-1 h-8 rounded">
        <option v-for="item in Categories" :key="item" :value="item">
          {{ item }}
        </option>
      </select>
      <select name="" id="" class="bg-primary-400 w-1/4 px-2 py-1 h-8 rounded">
        <option value="all">Tous</option>
        <option value="created" class="flex items-center">Créée</option>
        <option value="pending">En Cours</option>
        <option value="over">Terminée</option>
      </select>
      <button class="bg-primary-400 w-1/4 px-2 py-1 h-8 rounded">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
    </form> -->

    <SingleCompetition
      v-for="(competition, index) in competitions"
      :key="index"
      :competition="competition"
      :remove="removeCompetition"
    />

    <Modal @close="close" action="competition" v-if="modal" :data="data" />
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import api from "@/api";
import SingleCompetition from "@/components/SingleCompetition.vue";
import { Categories } from "@/constants";

export default {
  components: {
    Modal,
    SingleCompetition,
  },
  data() {
    return {
      data: {},
      competitions: {},
      modal: false,
    };
  },
  created() {
    this.Categories = Categories;
  },
  mounted() {
    this.getCompetitions();
  },
  methods: {
    async getCompetitions() {
      const res = await api.getCompetitions("all");
      if (!res.error) {
        this.competitions = res.data;
      }
    },
    createCompetition() {
      this.data = {
        title: "Ajouter une compétition",
        categories: Categories,
      };
      this.modal = !this.modal;
    },
    removeCompetition(competition) {
      const index = this.competitions.indexOf(competition);
      if (index > -1) {
        this.competitions.splice(index, 1);
      }
    },
    close() {
      this.getCompetitions();
      this.modal = !this.modal;
    },
  },
};
</script>
<template>
  <form @submit.prevent="addCompetition">
    <input
      type="text"
      v-model="form.name"
      placeholder="Nom de la compétition"
      class="
        bg-primary-900
        p-2
        border border-white
        rounded-lg
        block
        w-full
        focus:border-secondary
        outline-none
        mt-5
      "
    />

    <input
      type="text"
      v-model="form.location"
      placeholder="Lieu"
      class="
        bg-primary-900
        p-2
        border border-white
        rounded-lg
        block
        w-full
        focus:border-secondary
        outline-none
        my-3
      "
    />

    <input
      type="date"
      v-model="form.date"
      placeholder="Date"
      class="
        bg-primary-900
        p-2
        border border-white
        rounded-lg
        block
        w-full
        focus:border-secondary
        outline-none
        my-3
      "
    />
    <div class="flex gap-x-2">
      <select
        class="
          my-4
          w-1/2
          bg-primary-900
          p-2
          border border-white
          rounded-lg
          block
          outline-none
        "
        name="weapon"
        id="weapon"
        v-model="form.weapon"
      >
        <option value="" disabled selected class="text-white">Arme</option>
        <option value="épée">Epée</option>
        <option value="fleuret">Fleuret</option>
        <option value="sabre">Sabre</option>
      </select>

      <select
        class="
          my-4
          w-1/2
          bg-primary-900
          p-2
          border border-white
          rounded-lg
          block
          outline-none
        "
        name="gender"
        id="gender"
        v-model="form.gender"
      >
        <option value="" disabled selected class="text-white">Genre</option>

        <option value="femmes">Femmes</option>
        <option value="hommes">Hommes</option>
      </select>
    </div>

    <select
      class="
        my-4
        bg-primary-900
        p-2
        border border-white
        rounded-lg
        w-full
        block
        outline-none
      "
      name="level"
      v-model="form.category"
      id="level"
    >
      <option value="" disabled selected class="text-white">Catégorie</option>

      <option
        v-for="(category, index) in Categories"
        :value="index"
        :key="index"
      >
        {{ category }}
      </option>
    </select>

    <p class="text-red" v-if="error.message">{{ error.message }}</p>

    <div class="flex justify-end py-4 gap-x-4 mt-5">
      <button
        @click="$emit('exit')"
        class="bg-primary-400 text-white px-4 py-2 rounded-lg"
      >
        Annuler
      </button>
      <button
        type="submit"
        class="bg-secondary text-white px-4 py-2 rounded-lg"
      >
        <span v-if="!loading">Valider</span>
        <svg
          v-if="loading"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto"
          width="20px"
          height="20px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#fff"
            stroke-width="10"
            r="35"
            stroke-dasharray="164.93361431346415 56.97787143782138"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
      </button>
    </div>
  </form>
</template>

<script>
import api from "@/api";
export default {
  props: {
    Categories: Object,
  },
  data() {
    return {
      form: {
        weapon: "",
        date: "",
        gender: "",
        category: "",
      },
      error: {},
      loading: false,
    };
  },
  methods: {
    async addCompetition() {
      //submit form
      //then
      this.loading = !this.loading;
      this.error = {};
      const res = await api.createCompetition(this.form);
      if (!res.error) {
        if (res.data.status == "error") {
          this.error.message = res.data.message;
          this.loading = !this.loading;
        } else {
          this.loading = !this.loading;
          this.$emit("exit", true);
        }
      }
    },
  },
};
</script>
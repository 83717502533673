<template>
  <div>
    <div class="flex justify-between mt-10 bg-primary-600 p-4 rounded-lg">
      <h2 class="text-xl font-bold">
        {{ fencer.firstname }} {{ fencer.lastname }}
      </h2>
      <div
        :class="getColor(fencer.category)"
        class="inline-flex items-center px-2 py-1 rounded-full"
        v-if="fencer.category"
      >
        <small>{{ fencer.category | category }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      fencer: {},
      competition: {},
    };
  },
  async created() {
    const res = await api.getFencer(this.$route.params.fencerId);
    if (!res.error) {
      this.fencer = res.data.fencer;
      this.competitions = res.data.competitions;
      console.log(this.fencer);
    }
  },
  methods: {
    getColor(year) {
      var now = new Date().getFullYear();
      if (year <= now && year > now - 14) {
        return "bg-green";
      }
      if (year < now - 14 && year >= now - 17) {
        return "bg-secondary";
      }
      if (year < now - 17 && year >= now - 20) {
        return "bg-secondary";
      }
      if (year < now - 20 && year >= now - 23) {
        return "bg-green";
      }
      if (year < now - 24 && year >= now - 39) {
        return "bg-red";
      }
      if (year <= now - 40) {
        return "bg-green";
      }
    },
  },
};
</script>